import { fetchCompanyBankAccounts } from "apis/bankAccounts";
import { AvField, AvForm } from "availity-reactstrap-validation";
import React, { useEffect, useState } from "react";
import { Label } from "reactstrap";
import CustomSelect from "components/Common/CustomSelect";
import FeatherIcon from "feather-icons-react";
import { showSuccessNotification } from "store/actions";
import { useDispatch } from "react-redux";

export default function WireTransfer(props) {
  const { t, setIsFirstStepValid, setBaseCurrency, setPaymentPayload } = props;
  const [banks, setBanks] = useState([]);
  const [bankAccount, setBankAccount] = useState(null);
  const [filterBank, setFilterBank] = useState([]);
  const dispatch = useDispatch();
  useEffect(async () => {
    if (banks.length === 0) {
      setBanks(await fetchCompanyBankAccounts());
    }
  }, []);
  
  useEffect( () => {
    const filterBank = banks.filter((item)=> item.isActive === true);
    setFilterBank(filterBank);
  }, [banks]);

  useEffect(() => {
    if (bankAccount) {
      setIsFirstStepValid(true);
      setPaymentPayload({
        ...bankAccount,
        gateway: "WIRE_TRANSFER",
      });
    }
  }, [bankAccount]);

  console.log("Banks Banks", banks);
  return (
    <div className="my-3">
      <CustomSelect
        name="bankAccount"
        onChange={(e) => {
          setBankAccount(e.value);
          setBaseCurrency(e.value.currency);
        }}
        required
        placeholder="Select Bank Account"
        options={filterBank?.map((bank) => {
          return {
            label: `${bank.bankName} - ${
              bank.country ? bank.country : "No Found Country"
            }`,
            value: bank,
          };
        })}
      ></CustomSelect>
      {bankAccount && (
        <AvForm className="mt-4">
          <h5 className="mb-4">{t("Payment details")}</h5>
          <div>
            <Label>{t("Bank Holder Name")}</Label>
            <div className="d-flex">
              <AvField
                type="text"
                name="accountHolderName"
                value={bankAccount.accountHolderName}
                validate={{ required: true }}
                disabled={true}
              ></AvField>
              <div
                onClick={() => {
                  navigator.clipboard.writeText(bankAccount.accountHolderName);
                  dispatch(showSuccessNotification("Copied to clipboard"));
                }}
                className="cursor-pointer d-flex justify-content-center align-items-center"
                style={{
                  padding: 10,
                }}
              >
                <FeatherIcon icon="copy" />
              </div>
            </div>
          </div>
          <div>
            <Label>{t("Bank Name")}</Label>
            <div className="d-flex">
              <AvField
                type="text"
                name="bankName"
                value={bankAccount.bankName}
                validate={{ required: true }}
                disabled={true}
              ></AvField>
              <div
                onClick={() => {
                  navigator.clipboard.writeText(bankAccount.bankName);
                  dispatch(showSuccessNotification("Copied to clipboard"));
                }}
                className="cursor-pointer d-flex justify-content-center align-items-center"
                style={{
                  padding: 10,
                }}
              >
                <FeatherIcon icon="copy" />
              </div>
            </div>
          </div>

          <div>
            <Label>{t("Account Number")}</Label>
            <div className="d-flex">
              <AvField
                type="text"
                name="accountNumber"
                value={bankAccount.accountNumber}
                validate={{ required: true }}
                disabled={true}
              ></AvField>
              <div
                onClick={() => {
                  navigator.clipboard.writeText(bankAccount.accountNumber);
                  dispatch(showSuccessNotification("Copied to clipboard"));
                }}
                className="cursor-pointer d-flex justify-content-center align-items-center"
                style={{
                  padding: 10,
                }}
              >
                <FeatherIcon icon="copy" />
              </div>
            </div>
          </div>
          <div>
            <Label>{t("Address")}</Label>
            <div className="d-flex">
              <AvField
                type="text"
                name="address"
                value={bankAccount.address}
                validate={{ required: true }}
                disabled={true}
              ></AvField>
              <div
                onClick={() => {
                  navigator.clipboard.writeText(bankAccount.address);
                  dispatch(showSuccessNotification("Copied to clipboard"));
                }}
                className="cursor-pointer d-flex justify-content-center align-items-center"
                style={{
                  padding: 10,
                }}
              >
                <FeatherIcon icon="copy" />
              </div>
            </div>
          </div>
          <div>
            <Label>{t("Swift Code")}</Label>
            <div className="d-flex">
              <AvField
                type="text"
                name="swiftCode"
                value={bankAccount.swiftCode}
                validate={{ required: true }}
                disabled={true}
              ></AvField>
              <div
                onClick={() => {
                  navigator.clipboard.writeText(bankAccount.swiftCode);
                  dispatch(showSuccessNotification("Copied to clipboard"));
                }}
                className="cursor-pointer d-flex justify-content-center align-items-center"
                style={{
                  padding: 10,
                }}
              >
                <FeatherIcon icon="copy" />
              </div>
            </div>
          </div>
          <div>
            <Label>{t("Currency")}</Label>
            <div className="d-flex">
              <AvField
                type="text"
                name="currency"
                disabled={true}
                value={bankAccount.currency}
                validate={{ required: true }}
              ></AvField>
              <div
                onClick={() => {
                  navigator.clipboard.writeText(bankAccount.currency);
                  dispatch(showSuccessNotification("Copied to clipboard"));
                }}
                className="cursor-pointer d-flex justify-content-center align-items-center"
                style={{
                  padding: 10,
                }}
              >
                <FeatherIcon icon="copy" />
              </div>
            </div>
          </div>
          <p>{t("Bank Account")}</p>
          <p className="text-muted">
            {t(
              "You MUST include the Reference Code in your deposit in order to credit your account!"
            )}
          </p>
        </AvForm>
      )}
    </div>
  );
}
