/* eslint-disable indent */
import { createAccountAPI } from "apis/forex/accounts";
import { createAccountRequestAPI } from "apis/forex/requests";
import {
  AvFeedback,
  AvField,
  AvForm,
  AvGroup,
  AvRadio,
  AvRadioGroup,
} from "availity-reactstrap-validation";
import config from "config";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Col,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import {
  getAccountTypesStart,
  showErrorNotification,
  showSuccessNotification
} from "store/actions";

const CreateInvestorAccountModal = ({ isOpen, toggle }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [filteredAccountTypes, setFilteredAccountTypes] = useState([]);
  const [accountCurrency, setAccountCurrency] = useState([]);
  const [accountLeverages, setAccountLeverages] = useState([]);
  const { accountTypes } = useSelector((state) => state.forex.accounts);
  const [loading, setLoading] = useState(false);

  const confirmPassword = (value, ctx, input, cb) => {
    if (value !== ctx.password) cb("Password doesn't match!");
    else cb(true);
  };

  useEffect(() => {
    dispatch(
      getAccountTypesStart({
        forCp: true,
      })
    );
    return () => {
      dispatch(getAccountTypesStart());
    };
  }, []);

  // filter accounts based on account's type (INVESTOR)
  useEffect(() => {
    setFilteredAccountTypes(accountTypes?.filter((at) => at.type === "INVESTOR"));
  }, [accountTypes]);

  const { accounts } = useSelector((state) => state.forex.accounts);
  const isApprovalRequired = accounts?.length >= config.MAX_UNAPPROVED_ACCOUNTS;
  const createAccount = (v) => {
    createAccountAPI({
      ...v
    }).then((res) => {
      if (res.isSuccess) {
        dispatch(showSuccessNotification("Account created successfully!"));
        toggle();
        window.location.reload();
      } else {
        throw new Error(res.message);
      }
    }).catch((err) => {
      dispatch(showErrorNotification(err.message ?? "Something went wrong!"));
    }).finally(() => {
      setLoading(false);
    });
  };

  const createAccountRequest = (v) => {
    createAccountRequestAPI({
      ...v
    }).then((res) => {
      if (res.isSuccess) {
        dispatch(showSuccessNotification("Account request sent successfully!"));
        toggle();
        window.location.reload();
      } else {
        throw new Error(res.message);
      }
    }).catch((err) => {
      dispatch(showErrorNotification(err.message ?? "Something went wrong!"));
    }).finally(() => {
      setLoading(false);
    });
  };

  const handleValidSubmit = (e, v) => {
    setLoading(true);
    delete v.confirm_password;
    isApprovalRequired ? createAccountRequest(v) : createAccount(v);
    };

  return (
    <Modal centered isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle} tag="h4" className="text-capitalize">
        {t("Create Investor Account")}
      </ModalHeader>
      <ModalBody className="px-4">
        <AvForm
          onValidSubmit={(e, v) => {
            handleValidSubmit(e, v);
          }}
        >
          <Row className="border rounded-3 p-3 mt-3">
            <AvRadioGroup name="accountTypeId" errorMessage={t("Account type is required")} required>
              <Row className="radio-group">
                <h5>{t("Account Type")}</h5>
                {filteredAccountTypes.length > 0 && filteredAccountTypes.map((type) =>
                  <Col key={type._id} md="6" className="gy-3">
                    <div className="custom-radio"
                      onClick={() => {
                        setAccountCurrency(type.currencies);
                        setAccountLeverages(type.leverages);
                        document.getElementById(`radio-accountTypeId-${type._id}`).click();
                      }}  style={{
                        border: 0,
                      }}>
                      <AvRadio label={t(type.title)} value={type._id} />
                    </div>
                  </Col>
                )}
              </Row>
            </AvRadioGroup>
          </Row>
          {accountCurrency.length > 0 &&
            <Row className="border rounded-3 p-3 mt-3">
              <AvRadioGroup name="currency" errorMessage={t("Currency is required")} required>
                <Row  className="radio-group">
                  <h5 className="mb-3">{t("Account Currency")}</h5>
                  {accountCurrency.map((currency) =>
                    <Col key={currency.currency} md="6" className="gy-3">
                      <div className="custom-radio"
                        onClick={() => {
                          document.getElementById(`radio-currency-${currency.currency}`).click();
                        }}  style={{
                          border: 0,
                        }}>
                        <AvRadio label={t(currency.currency)} value={currency.currency} />
                      </div>
                    </Col>
                  )}
                </Row>
              </AvRadioGroup>
            </Row>}
          <Row className="border rounded-3 p-3 mt-3">
            <h5 className="mb-3">{t("Select Leverage")}</h5>
            <AvField type="select" name="leverage" value={"1"} required>
              {accountLeverages?.map((leverage) =>
                <option key={leverage} value={leverage}>{`1:${leverage}`}</option>
              )}
            </AvField>
          </Row>
          {
            !isApprovalRequired ? 
              <Row className="border rounded-3 p-3 mt-3">
                <Col md="6">
                  <AvGroup>
                    <Label for="password" className="mb-2">{t("Trading Account Password")}</Label>
                    <AvField name="password" type="password" id="password"
                      validate={{
                        required: {
                          value: true,
                          errorMessage: "Password is required"
                        },
                        pattern: {
                          value: /^(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]/,
                          errorMessage: "Password must contain at least one uppercase letter and one number"
                        },
                        minLength: {
                          value: 7,
                          errorMessage: "Password must be more than 6 characters"
                        },
                      }} />
                  </AvGroup>
                </Col>
                <Col md="6">
                  <AvGroup>
                    <Label for="confirm_password" className="mb-2">{t("Confirm Password")}</Label>
                    <AvField name="confirm_password" type="password" id="confirm_password"
                      validate={{
                        required: {
                          value: true,
                          errorMessage: "Password is required"
                        },
                        custom: confirmPassword
                      }}
                    />
                    <AvFeedback>{t("Password doesn't match")}</AvFeedback>
                  </AvGroup>
                </Col>
              </Row>
              : null
          }
          {
            isApprovalRequired ? 
              <Row className="border rounded-3 p-3 mt-3">
                <AvGroup>
                  <Label for="reason" className="mb-2">{t("Reason for new Account")}</Label>
                  <AvField name="reason" type="reason" id="reason"
                    validate={{
                      required: {
                        value: true,
                        errorMessage: "Reason is required"
                      },
                      minLength: {
                        value: 7,
                        errorMessage: "Reason must have at least 6 characters"
                      },
                    }} />
                </AvGroup>
              </Row>
              : null  
          }
          <div className="text-center mt-3 mb-1">
            <Button type="submit" disabled={loading} className="color-bg-btn border-0 text-white w-25">{t("Submit")}</Button>
            <Button color="light" type="button" className="w-25 ms-3"
              onClick={toggle}
            >{t("Skip")}</Button>
          </div>
        </AvForm>
      </ModalBody>
    </Modal >
  );
};

export default CreateInvestorAccountModal;
