/* eslint-disable indent */
import CardWrapper from "components/Common/CardWrapper";
import { useTranslation } from "react-i18next";
// eslint-disable-next-line object-curly-newline
import {
  Button, Col, Row,
  Alert,
} from "reactstrap";
import CreateInvestorAccountModal from "../../Investor/Accounts/CreateInvestorAccountModal";
import classNames from "classnames";
import Icofont from "react-icofont";
import Select, { components } from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { toggleCurrentModal } from "store/actions";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import UnlinkMasterModal from "../../Investor/Accounts/UnlinkMasterModal";
import LinkInvestorToProfile from "./LinkInvestorToProfile";
import { JClickHandler } from "components/Journey/handlers";
import { useEffect, useState } from "react";
import { getPendingRequestAPI } from "apis/forex/requests";
import PendingRequestBadge from "components/Forex/Common/PendingRequestBadge";

const { SingleValue, Option } = components;

const AccountSelect = (props) => {
  const { layoutMode } = useSelector((state) => state.Layout);
  const { t } = useTranslation();

  const IconSingleValue = (props) => (
    <SingleValue {...props}>
      <div
        className="color-primary font-weight-bold border-0"
        style={{ fontSize: "14.4px" }}
      >
        {t("Account No")}
      </div>
      <div style={{ fontWeight: "bold" }}>{props.data.label}</div>
    </SingleValue>
  );

  const IconOption = (props) => <Option {...props}>{props.data.label}</Option>;
  const customStyles = {
    dropdownIndicator: (provided) => ({
      ...provided,
      color: layoutMode === "dark" ? "white" : "#495057",
      padding: 0,
      backgroundColor: "transparent",
      paddingRight: "5px",
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      display: "none",
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      alignItems: "start",
    }),
    control: (provided) => {
      if (layoutMode === "dark") {
        return {
          ...provided,
          backgroundColor: "#1A2A3E",
          border: "0 solid #CED4DA",
          boxShadow: "0 0.125rem 0.25rem #1e2128",
          color: "#adb5bd",
          height: "100%",
          borderRadius: "10px",
          padding: "10px",
          alignItems: "start",
        };
      }
      return {
        ...provided,
        borderRadius: "10px",
        padding: "17px",
        height: "100%",
        border: "0px",
        boxShadow: "0 0.5rem 1rem rgb(0 0 0 / 10%)",
        cursor: "pointer",
        background: "transparent",
        alignItems: "start",
      };
    },
    menu: (provided) => ({
      ...provided,
      backgroundColor: layoutMode === "dark" ? "#1A2A3E" : "white",
      color: layoutMode === "dark" ? "#adb5bd" : "#495057",
      zIndex: 3,
    }),
    option: (provided, state) => ({
      ...provided,
      display: state.isDisabled ? "none" : "flex",
      flexDirection: "row",
      alignItems: "center",
      color: layoutMode === "dark" ? "#adb5bd" : "#495057",
      padding: "10px",
    }),
    singleValue: (provided) => {
      return {
        ...provided,
        flexDirection: "row",
        alignItems: "center",
        color: layoutMode === "dark" ? "#adb5bd" : "#495057",
      };
    },
  };

  const value = props.options.find(
    (option) => option.value?.investorId === props.value?.investorId
  );

  return (
    <>
      <div
        style={{
          backgroundColor: "transparent",
          height: "100%",
        }}
      >
        <Select
          {...props}
          className={"basic-single color-primary shadow border-0"}
          placeholder={t("No Investor Account Selected")}
          styles={customStyles}
          onInputChange={(input) => {
            if (input !== "") {
              if (!/^-?\d*\.?\d*$/.test(input)) {
                return "";
              }
            }
            return input;
          }}
          components={{
            SingleValue: IconSingleValue,
            Option: IconOption,
          }}
          options={props.options}
          value={value}
        />
      </div>
    </>
  );
};

const InvestorAccountDetail = (props) => {
  const { operations, selectedProfile, setSelectedProfile, profiles = [] } =
    props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [requestLoading, setRequestLoading] = useState(false);
  const [linkMasterRequest, setLinkMasterRequest] = useState({});
  const [unlinkMasterRequest, setUnLinkMasterRequest] = useState({});

  const { clientData } = useSelector((state) => state.Profile);
  const { layoutMode } = useSelector((state) => state.Layout);
  const { currentModal } = useSelector((state) => ({
    currentModal: state.Layout.currentModal,
    modalData: state.Layout.modalData,
  }));


  const getProfileName = (profileType) => {
    switch (profileType) {
      case 1 : return "Equity Lot Share - MAM";
      case 2 : return "Equity Cash Share - PAMm";
      case 9: return "Copy Trading";
      default : return "";
    }
  };

  const fetchPendingRequest = () => {
    if (selectedProfile) {
      setRequestLoading(true);
      getPendingRequestAPI({
        tradingAccountId: selectedProfile?.investorId,
        status: "PENDING",
      }).then((res) => {
        if (res?.result) {
          const { result } = res;
          const linkMaster = result[0];
          const unlinkMaster = result[1];
          setLinkMasterRequest(linkMaster);
          setUnLinkMasterRequest(unlinkMaster);
        }
      }).catch((err) => {
        console.log(err);
      }).finally(() => {
        setRequestLoading(false);
      });
    }
  };

  useEffect(() => {
    if (selectedProfile) {
      fetchPendingRequest();      
    }
  }, [selectedProfile]);

  return (
    <>
      <h1 className="color-primary">{t("Investor Account Detail")}</h1>
      <CardWrapper
        className="accounts-tab shadow mt-4 glass-card"
      >
        {operations && (
          <Row className="d-flex align-items-center my-3">
            {/* {profiles && profiles.length === 0 && (
              <Col className="my-2">
                <PendingRequestBadge text="You need to link the account to a profile" />
              </Col>
            )} */}
            <Col className="d-inline-block pb-3 d-md-flex justify-content-end">
              <div className="text-end d-flex padding-right-3 flex-column flex-md-row">
                <Button
                  color="light"
                  className="btn-light my-2 mx-1 shadow-lg bg-white border-0 color-bg-btn btn btn-secondary"
                  onClick={() =>
                    JClickHandler("CreateInvestorAccountModal", {
                      ...clientData.stages,
                      loaded: true,
                    }, dispatch, toggleCurrentModal, "INVESTOR")()
                  }
                >
                  <Icofont className="me-2" icon="plus-circle" />
                  {t("Create an Investor Account")}
                </Button>

                <Button
                  color="light"
                  className="btn-light my-2 mx-1 shadow-lg bg-white border-0 color-bg-btn btn btn-secondary"
                  onClick={() => history.push("/accounts/password")}
                  disabled={profiles?.length === 0}
                >
                  <Icofont className="me-2" icon="lock-circle" />
                  {t("Change Password")}
                </Button>
                {
                  selectedProfile?.profileType && <Button
                    color="light"
                    onClick={() =>
                      JClickHandler("UnlinkMasterModal", {
                        ...clientData.stages,
                        loaded: true,
                      }, dispatch, toggleCurrentModal, "INVESTOR")()
                    }
                    className="btn-light my-2 mx-1 shadow-lg bg-white border-0 color-bg-btn btn btn-secondary"
                    disabled={!selectedProfile || unlinkMasterRequest?.status === "PENDING"}
                >
                  {t("Unlink Master")}
                </Button>
                }
                {
                  !selectedProfile?.profileType && <Button
                    color="light"
                    onClick={() =>
                      JClickHandler("LinkMasterModal", {
                        ...clientData.stages,
                        loaded: true,
                      }, dispatch, toggleCurrentModal, "INVESTOR")()
                    }
                    className="btn-light my-2 mx-1 shadow-lg bg-white border-0 color-bg-btn btn btn-secondary"
                    disabled={!selectedProfile || linkMasterRequest?.status === "PENDING"}
                >
                  {t("Link Master")}
                </Button>
                }
              </div>
            </Col>
          </Row>
        )}
        <Row>
          {unlinkMasterRequest?.status === "PENDING" && <Col lg={12} md={12} xs={12} className="text-center w-100 my-3">
            <Alert className="mx-5" color="info">{t("Unlink Request is Pending!")}</Alert>
          </Col>}
          {linkMasterRequest?.status === "PENDING" && <Col lg={12} md={12} xs={12} className="text-center w-100 my-3">
            <Alert className="mx-5" color="info">{t("Link Request is Pending!")}</Alert>
          </Col>}
        </Row>
        <Row style={{ "--bs-gutter-y": "20px" }}>
          {
            profiles && profiles.length > 0 ? <>
              <Col lg={2} md={4} xs={12} className="flex-fill">
                <div className="mb-3 h-100">
                  <AccountSelect
                    options={
                      profiles?.map((profile) => ({
                        label: `${profile?.selectedInvestorAccount?.login}`,
                        value: profile,
                      })) ?? []
                    }
                    value={selectedProfile}
                    onChange={(newVal) => {
                      setSelectedProfile(newVal.value);
                    }}
                  />
                </div>
              </Col>
              {/* <Col lg={2} md={4} xs={12}>
                <div
                  className={classNames(
                    "acc-tab-card mb-3 h-100 shadow-lg border border-0"
                  )}
                >
                  <button
                    type="button"
                    aria-haspopup="true"
                    aria-expanded="false"
                    className="text-start w-100 p-0 mb-1 btn-white"
                  >
                    <div className="acc-tab-card-title color-primary">
                      {t("Signal Provider")}
                    </div>
                    <div className="acc-tab-card-desc">
                      {selectedProfile?.masterTradingAccountId?.login ??
                        "-"}
                    </div>
                  </button>
                </div>
              </Col> */}
              <Col lg={2} md={4} xs={12}>
                <div
                  className={classNames(
                    "acc-tab-card mb-3 h-100 shadow-lg border border-0"
                  )}
                >
                  <button
                    type="button"
                    aria-haspopup="true"
                    aria-expanded="false"
                    className="text-start w-100 p-0 mb-1 btn-white"
                  >
                    <div className="acc-tab-card-title color-primary">
                      {t("Master Name")}
                    </div>
                    <div className={`acc-tab-card-desc ${layoutMode === "dark" ? "text-white" : "text-dark"}`}>
                      {selectedProfile?.username ?? "-"}
                    </div>
                  </button>
                </div>
              </Col>
              <Col lg={2} md={4} xs={12}>
                <div
                  className={classNames(
                    "acc-tab-card mb-3 h-100 shadow-lg border border-0"
                  )}
                >
                  <button
                    type="button"
                    aria-haspopup="true"
                    aria-expanded="false"
                    className="text-start w-100 p-0 mb-1 btn-white"
                  >
                    <div className="acc-tab-card-title color-primary">
                      {t("Performance Fee - (%)")}
                    </div>
                    <div className={`acc-tab-card-desc ${layoutMode === "dark" ? "text-white" : "text-dark"}`}>
                      {selectedProfile?.feeSetting?.performanceFee ?? "-"}
                    </div>
                  </button>
                </div>
              </Col>
              <Col lg={2} md={4} xs={12}>
                <div
                  className={classNames(
                    "acc-tab-card mb-3 h-100 shadow-lg border border-0"
                  )}
                >
                  <button
                    type="button"
                    aria-haspopup="true"
                    aria-expanded="false"
                    className="text-start w-100 p-0 mb-1 btn-white"
                  >
                    <div className="acc-tab-card-title color-primary">
                      {t("Management Fee - ($)")}
                    </div>
                    <div className={`acc-tab-card-desc ${layoutMode === "dark" ? "text-white" : "text-dark"}`}>
                      {selectedProfile?.feeSetting?.managementFee ?? "-"}
                    </div>
                  </button>
                </div>
              </Col>
              <Col lg={2} md={4} xs={12}>
                <div
                  className={classNames(
                    "acc-tab-card mb-3 h-100 shadow-lg border border-0"
                  )}
                >
                  <button
                    type="button"
                    aria-haspopup="true"
                    aria-expanded="false"
                    className="text-start w-100 p-0 mb-1 btn-white"
                  >
                    <div className="acc-tab-card-title color-primary">
                      {t("Periodic Management Fee - ($)")}
                    </div>
                    <div className={`acc-tab-card-desc ${layoutMode === "dark" ? "text-white" : "text-dark"}`}>
                      {selectedProfile?.feeSetting?.periodicManagementFee ??
                        "-"}
                    </div>
                  </button>
                </div>
              </Col>
              <Col lg={2} md={4} xs={12}>
                <div
                  className={classNames(
                    "acc-tab-card mb-3 h-100 shadow-lg border border-0"
                  )}
                >
                  <button
                    type="button"
                    aria-haspopup="true"
                    aria-expanded="false"
                    className="text-start w-100 p-0 mb-1 btn-white"
                  >
                    <div className="acc-tab-card-title color-primary">
                      {t("Per Lot Fee - ($)")}
                    </div>
                    <div className={`acc-tab-card-desc ${layoutMode === "dark" ? "text-white" : "text-dark"}`}>
                      {selectedProfile?.feeSetting?.perLotFee ?? "-"}
                    </div>
                  </button>
                </div>
              </Col>
              <Col lg={2} md={4} xs={12}>
                <div
                  className={classNames(
                    "acc-tab-card mb-3 h-100 shadow-lg border border-0"
                  )}
                >
                  <button
                    type="button"
                    aria-haspopup="true"
                    aria-expanded="false"
                    className="text-start w-100 p-0 mb-1 btn-white"
                  >
                    <div className="acc-tab-card-title color-primary">
                      {t("Allocation Type")}
                    </div>
                    <div className={`acc-tab-card-desc ${layoutMode === "dark" ? "text-white" : "text-dark"}`}>
                      {selectedProfile ? (getProfileName(selectedProfile?.profileType)) : "-"}
                    </div>
                  </button>
                </div>
              </Col>
              <Col lg={2} md={4} xs={12}>
                <div
                  className={classNames(
                    "acc-tab-card mb-3 h-100 shadow-lg border border-0"
                  )}
                >
                  <button
                    type="button"
                    aria-haspopup="true"
                    aria-expanded="false"
                    className="text-start w-100 p-0 mb-1 btn-white"
                  >
                    <div className="acc-tab-card-title color-primary">
                      {t("Balance")}
                    </div>
                    <div className={`acc-tab-card-desc ${layoutMode === "dark" ? "text-white" : "text-dark"}`}>
                      {selectedProfile?.selectedInvestorAccount?.Balance ?? "-"}
                    </div>
                  </button>
                </div>
              </Col>
              <Col lg={2} md={4} xs={12}>
                <div
                  className={classNames(
                    "acc-tab-card mb-3 h-100 shadow-lg border border-0"
                  )}
                >
                  <button
                    type="button"
                    aria-haspopup="true"
                    aria-expanded="false"
                    className="text-start w-100 p-0 mb-1 btn-white"
                  >
                    <div className="acc-tab-card-title color-primary">
                      {t("Credit")}
                    </div>
                    <div className={`acc-tab-card-desc ${layoutMode === "dark" ? "text-white" : "text-dark"}`}>
                      {selectedProfile?.selectedInvestorAccount?.Credit ?? "-"}
                    </div>
                  </button>
                </div>
              </Col>
              <Col lg={2} md={4} xs={12}>
                <div
                  className={classNames(
                    "acc-tab-card mb-3 h-100 shadow-lg border border-0"
                  )}
                >
                  <button
                    type="button"
                    aria-haspopup="true"
                    aria-expanded="false"
                    className="text-start w-100 p-0 mb-1 btn-white"
                  >
                    <div className="acc-tab-card-title color-primary">
                      {t("Margin")}
                    </div>
                    <div className={`acc-tab-card-desc ${layoutMode === "dark" ? "text-white" : "text-dark"}`}>
                      {selectedProfile?.selectedInvestorAccount?.Margin ?? "-"}
                    </div>
                  </button>
                </div>
              </Col>
              <Col lg={2} md={4} xs={12}>
                <div
                  className={classNames(
                    "acc-tab-card mb-3 h-100 shadow-lg border border-0"
                  )}
                >
                  <button
                    type="button"
                    aria-haspopup="true"
                    aria-expanded="false"
                    className="text-start w-100 p-0 mb-1 btn-white"
                  >
                    <div className="acc-tab-card-title color-primary">
                      {t("Equity")}
                    </div>
                    <div className={`acc-tab-card-desc ${layoutMode === "dark" ? "text-white" : "text-dark"}`}>
                      {selectedProfile?.selectedInvestorAccount?.Equity ?? "-"}
                    </div>
                  </button>
                </div>
              </Col>
            </> :
              <Col lg={12} md={12} xs={12} className="text-center w-100 p-5">
                <h5 className="color-primary">{t("No Investor Account Found!")}</h5>
              </Col>
          }
        </Row>
      </CardWrapper>
      {currentModal === "UnlinkMasterModal" && (
        <UnlinkMasterModal
          isOpen={currentModal === "UnlinkMasterModal"}
          toggle={() => {
            dispatch(toggleCurrentModal(""));
          }}
          profileId={selectedProfile?._id}
          selectedAccount={selectedProfile?.masterTradingAccountId}
          selectedInvestorAccount={selectedProfile?.selectedInvestorAccount}
        />
      )}
      {currentModal === "LinkMasterModal" && (
        <>
          <LinkInvestorToProfile
            isOpen={currentModal === "LinkMasterModal"}
            toggle={() => {
              dispatch(toggleCurrentModal(""));
            }}
          />
        </>
      )}
    </>
  );
};

export default InvestorAccountDetail;
