import React from "react";
import { connect } from "react-redux";
import {
  CardBody, CardTitle, Modal, Button, ModalHeader
} from "reactstrap";
//i18n
import { withTranslation } from "react-i18next";
import CloseButton from "react-bootstrap/CloseButton";

const TempSuspension = (props) => {
  return (<React.Fragment>
    <Modal
      isOpen={props.isOpen}
      toggle={() => props.toggle()}
      centered={true}
      //   size="lg"
      className='custom-modal'
    >
      <div style={{
        padding: 20,
      }}>
        <ModalHeader className="d-flex flex-column gap-3">
          <CloseButton
            onClick={() => props.toggle()}
            style={{ 
              alignSelf: "flex-end",
              position: "absolute", 
              right: 10,
              top: 10 
            }} 
          />
          <div className="text-center">
            <CardTitle className="mb-0">
              <strong className="fw-bold">
                {"-Important Update: Enhanced KYC Verification -"}<br/>
              </strong>
            </CardTitle>
          </div>
        </ModalHeader>
        <CardBody>
          <p className="m-5 text-center">
            {"Dear Valued Client,"}
            <br /><br />
            {"We’re excited to announce that we’ve upgraded our KYC system to a new, AI-powered solution that provides advanced security and efficiency!"}
            <br /><br />
            <p>
              <strong className="fw-bold">{"For Existing Clients: "}</strong>
              <span>
                {" Starting from "}
              </span>
              <span className="fw-bold">
                {"September 10, 2024"}
              </span>
              <span>
                {", please log in to your account to complete the KYC verification using the new AI system."}
              </span>
            </p>

            <br /><br />
            <p>
              <span className="fw-bold">
                {"For New Registrations: "}
              </span>
              {"All new accounts will be verified through the AI-powered KYC system from day one."}
            </p>

            <br /><br />
            {"Thank you for your attention to this important update. If you have any questions or need assistance, please contact our support team."}
            <br /><br />
            {"Best regards,"}
            <br />
            {"Rox Capitals"}
          </p>

          <div className="text-center">
            <Button onClick={() => props.toggle()} color="success" className="w-lg waves-effect waves-light m-2">
              {props.t("Ok")}
            </Button>
          </div>
        </CardBody>
      </div>
    </Modal>
  </React.Fragment>);
};

const mapStateToProps = (state) => ({
//   profile: state.Profile && state.Profile.clientData || {},
});
export default connect(mapStateToProps, null)(withTranslation()(TempSuspension)); 