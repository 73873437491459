import CardWrapper from "components/Common/CardWrapper";
import CardHeader from "components/Forex/Common/CardHeader";
import PageHeader from "components/Forex/Common/PageHeader";
import Journey from "components/Journey/Journey";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Container,
} from "reactstrap";
import PaymentDetails from "./Form/PaymentDetails";
import PaymentType from "./PaymentType/PaymentType";
import PaymentGateway from "./PaymentGateway/PaymentGateway";
import FeatherIcon from "feather-icons-react";

function Deposit() {
  const { t } = useTranslation();
  const [stage, setStage] = useState(1);
  const [type, setType] = useState("");
  const [gateway, setGateway] = useState("");

  useEffect(() => {
    if (type && gateway) {
      setStage(3);
    } else if (type) {
      setStage(2);
    } else if (stage !== 1) {
      setStage(1);
    }
  }, [type, gateway, stage]);

  const steps = [
    {
      stage: 1,
      icon: "list",
    },
    {
      stage: 2,
      icon: "layers",
    }, 
    {
      stage: 3,
      icon: "file-text"
    },
    {
      stage: 4,
      icon: "check-circle"
    }
  ];

  return ( <>
    <div className="page-content">
      <Container className="mt-5">
        <PageHeader title="Deposit"></PageHeader>
        <div className="mt-3">
          <Journey></Journey>
        </div>        
        <CardWrapper className="mt-5">
          <CardHeader title={t("Make a Deposit")}></CardHeader>
          <div className="mb-3 text-center my-2 d-flex justify-content-around">
            {steps.map(({ stage: st, icon }) => (
              <span key={st} style={{
                marginRight: "10px",
                cursor: "pointer"
              }}>
                {stage > st ? (
                  <span style={{
                    color: "green"
                  }}>
                    <FeatherIcon icon={"check-circle"}></FeatherIcon>
                  </span>
                ) : (
                  <span style={{ color: stage < st ? "grey" : "black" }}>
                    <FeatherIcon icon={icon}></FeatherIcon>
                  </span>
                )}
                {st < steps && (
                  <>
                    line
                  </>
                )}
              </span>
            ))}
          </div>
          {stage === 1 && <PaymentType
            type={type}
            setType={setType}
          ></PaymentType>}
          {stage === 2 && <PaymentGateway
            type={type}
            setGateway={setGateway}
            setType={setType}
          />}
          {stage === 3 && <PaymentDetails
            gateway={gateway}
            type={type}
            setGateway={setGateway}
          />}
        </CardWrapper>
      </Container> 
    </div>
  </> );
}

export default Deposit;