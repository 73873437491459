/* eslint-disable object-curly-newline */
import { useTranslation, withTranslation } from "react-i18next";
import { Button, CardBody, CardFooter, CardTitle, Col, Container, Row, Spinner } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect, useState, useMemo } from "react";
import { getAllProfilesAPI } from "apis/forex/mam_pam";
import { capitalize } from "lodash";
import CardWrapper from "components/Common/CardWrapper";
import { JClickHandler } from "components/Journey/handlers";
import { toggleCurrentModal } from "store/actions";
import LinkModal from "pages/Forex/Dashboard/InvestorPortal/MasterTraders/LinkModal";
import CardView from "./CardView";
import RowView from "./RowView";
import {
  Tbody, Th, Thead, Tr, Table
} from "react-super-responsive-table";
import ProfileBasicStats from "pages/Forex/Dashboard/InvestorPortal/MasterTraders/ProfileBasicStats";
import { MetaTags } from "react-meta-tags";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const MasterTraders = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { subPortal } = useSelector((state) => state.Layout);
  const { clientData } = useSelector((state) => state.Profile);
  const { currentModal } = useSelector((state) => ({
    currentModal: state.Layout.currentModal,
    modalData: state.Layout.modalData,
  }));
  const [loading, setLoading] = useState(true);
  const [profile, setProfile] = useState(null);
  const [masterTraders, setMasterTraders] = useState([]);
  const [viewByCard, setViewByCard] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const fetchMasterTraders = useCallback((pageNum) => {
    setLoading(true);
    getAllProfilesAPI({
      params: {
        page: pageNum,
        limit: 8,
        profileType: {
          $in: subPortal === "LIVE" ? [1, 2, 9] : [1, 2, 9],
        },
        useCache: true,
      }
    }).then((res) => {
      if (res.result) {
        const { docs, totalPages } = res.result;
        const newMasterTraders = docs?.map((item) => ({
          _id: item._id,
          name: `${capitalize(item.customerId?.firstName)} ${capitalize(item.customerId?.lastName)}`,
          investors: item.slaves?.length,
          profileId: item._id,
          equity: item.masterTradingAccountId?.Equity ?? 0,
          login: item.masterTradingAccountId?.login,
          ...item,
        }));
        setMasterTraders(newMasterTraders);
        setTotalPages(totalPages);
        setCurrentPage(pageNum);
      }
    }).catch((err) => {
      console.log(err);
    }).finally(() => {
      setLoading(false);
    });
  }, [subPortal]);

  useEffect(() => {
    fetchMasterTraders(1);
  }, [fetchMasterTraders]);

  const getProfileName = (profileType) => {
    switch (profileType) {
      case 1 : return " Equity Lot Share - MAM";
      case 2 : return " Equity Cash Share - PAMM";
      case 9: return " Copy Trading";
      default : return "";
    }
  };

  const cardView = useCallback((traders)=>{
    return (
      <Row className="mt-2">
        {traders?.map((masterTrader, key) => (
          <CardView key={key} masterTrader={masterTrader} setProfile={setProfile} />
        ))}
      </Row>
    );
  }, []);

  const bestMasterTraders = useMemo(() => {
    return masterTraders
      .sort((a, b) => b.masterTradingAccountId?.totalProfit - a.masterTradingAccountId?.totalProfit)
      .sort((a, b) => b.investors - a.investors)
      .slice(0, 4);
  }, [masterTraders]);
  
  const SkeletonLoader = () => (
    <Col md={3} className="my-2">
      <CardWrapper className="accounts-tab shadow glass-card">
        <CardTitle className="text-center">
          <Skeleton height={50} />
        </CardTitle>
        <CardBody className="my-3 text-center">
          <Skeleton count={5} />
        </CardBody>
        <CardFooter>
          <Skeleton height={40} />
        </CardFooter>
      </CardWrapper>
    </Col>
  );

  return (
    <>
      <MetaTags>
        <title>Master Traders</title>
      </MetaTags>
    
      <Container className="mt-5 forex-dashboard">
        <div className="page-content">
        </div>
        <h1 className="color-primary">{t("Best Master Traders")}</h1>
        <Row className="mt-2">
          {loading ? (
            <>
              <SkeletonLoader />
              <SkeletonLoader />
              <SkeletonLoader />
              <SkeletonLoader />
            </>
          ) : (
            bestMasterTraders.map((masterTrader, key) => (
              <Col key={key} md={3} className="my-2">
                <CardWrapper className="accounts-tab shadow glass-card">
                  <CardTitle className="text-center">
                    <ProfileBasicStats profile={masterTrader} />
                  </CardTitle>
                  <CardBody className="my-3 text-center">
                    <div className="d-flex justify-content-between">
                      <h6>{t("Username")}: </h6>
                      <h6 className="color-primary">{masterTrader.username ?? 0}</h6>
                    </div>
                    <div className="d-flex justify-content-between">
                      <h6>{t("Equity")}: </h6>
                      <h6 className="color-primary">{masterTrader.equity ?? 0}</h6>
                    </div>
                    <div className="d-flex justify-content-between my-2">
                      <h6>{t("Investors")}: </h6>
                      <h6 className="color-primary">{masterTrader.investors ?? 0}</h6>
                    </div>
                    <div className="d-flex justify-content-between my-1">
                      <h6>{t("Profile Type")}: </h6>
                      <h6 className="color-primary">{getProfileName(masterTrader.profileType)}</h6>
                    </div>
                    <div className="d-flex justify-content-between">
                      <h6>{t("Total Profit Made")}: </h6>
                      <h6 className="color-primary">{masterTrader.masterTradingAccountId?.totalProfit ?? 0}</h6>
                    </div>
                  </CardBody>
                  <CardFooter>
                    <Button className="btn btn-primary color-bg-btn border-0" color="primary w-100" onClick={()=>{
                      setProfile(masterTrader);
                      JClickHandler("Invest", {
                        ...clientData.stages,
                        loaded: true,
                      }, dispatch, toggleCurrentModal, "INVESTOR")();
                    }}>
                      {t("Invest")}
                    </Button>
                  </CardFooter>
                </CardWrapper>
              </Col>
            ))
          )}
        </Row>
        {masterTraders.length > 0 && (
          <Row className="mt-3">
            <h1 className="color-primary">{t("All Master Traders")}</h1>
            <Row className="my-3">
              <div className="d-flex justify-content-end">
                <Button
                  className="btn btn-primary color-bg-btn border-0 mx-2"
                  color="primary"
                  onClick={() => {
                    setViewByCard(false);
                  }}
                >
                  <i className="bx bx-list-ul bx-sm"></i>
                </Button>
                <Button
                  className="btn btn-primary color-bg-btn border-0 mx-1"
                  color="primary"
                  onClick={() => {
                    setViewByCard(true);
                  }}
                >
                  <i className="bx bx-grid-alt bx-sm"></i>
                </Button>
              </div>
            </Row>
            {viewByCard ? (
              <>
                {loading ? (
                  <Row className="mt-2">
                    <SkeletonLoader />
                    <SkeletonLoader />
                    <SkeletonLoader />
                    <SkeletonLoader />
                  </Row>
                ) : (
                  cardView(masterTraders)
                )}
              </>
            ) : (
              <Row className="mt-2">
                <div className="table-rep-plugin custom-table" style={{ width: "100%" }}>
                  <Table responsive className="table table-hover table-borderless text-center">
                    <Thead>
                      <Tr className="my-5">
                        <Th></Th>
                        <Th><h3>{t("Username")}</h3></Th>
                        <Th><h3>{t("Profile Type")}</h3></Th>
                        <Th><h3>{t("Investors")}</h3></Th>
                        <Th><h3>{t("Total Investment")}</h3></Th>
                        <Th><h3>{t("Total Profit")}</h3></Th>
                        <Th><h3>{t("Created At")}</h3></Th>
                        <Th><h3>{t("Action")}</h3></Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {loading ? (
                        <Tr>
                          <td colSpan="8"><Skeleton count={5} /></td>
                        </Tr>
                      ) : (
                        masterTraders?.map((masterTrader, key) => (
                          <RowView key={key} masterTrader={masterTrader} setProfile={setProfile} />
                        ))
                      )}
                    </Tbody>
                  </Table>
                </div>
              </Row>
            )}
            <Row className="mt-3 justify-content-center">
              <Col md={4} className="d-flex justify-content-between">
                <Button
                  color="primary"
                  onClick={() => fetchMasterTraders(currentPage - 1)}
                  disabled={currentPage === 1 || loading}
                >
                  {t("Previous")}
                </Button>
                <span className="align-self-center">
                  {t("Page")} {currentPage} {t("of")} {totalPages}
                </span>
                <Button
                  color="primary"
                  onClick={() => fetchMasterTraders(currentPage + 1)}
                  disabled={currentPage === totalPages || loading}
                >
                  {t("Next")}
                </Button>
              </Col>
            </Row>
          </Row>
        )}
        {currentModal === "Invest" && profile?._id && 
          <LinkModal isOpen={currentModal === "Invest"} toggle={()=> {
            dispatch(toggleCurrentModal(""));
          }}  profileId={profile?._id} selectedProfile={profile} />
        }
      </Container>
    </>
  );
};
export default withTranslation()(MasterTraders);
