import { AvField, AvForm } from "availity-reactstrap-validation";
import AvFieldSelecvt from "components/Common/AvFieldSelect";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
// eslint-disable-next-line object-curly-newline
import { Button, Label, Modal, ModalBody, ModalHeader } from "reactstrap";
import { getAccountTypesStart, requestMaster } from "store/actions";

const deductionSchedule = [
  {
    key: 1,
    name: "Daily",
  },
  {
    key: 2,
    name: "Weekly",
  },
  {
    key: 3,
    name: "Monthly",
  },
  {
    key: 4,
    name: "Quarterly",
  },
  {
    key: 6,
    name: "Annually",
  },
];

const allocationStrategies = [
  {
    key: 1,
    name: "Equity Lot Share - MAM",
  },
  {
    key: 2,
    name: "Equity Cash Share - PAMM",
  },
  {
    key: 9,
    name: "Copy Trading - Social Trading"
  }
  // { key: 3, name: "Balance Lot Share" },
  // { key: 4, name: "Percentage" },
  // { key: 5, name: "Equal volume" },
  // { key: 6, name: "Multiplier" },
  // { key: 7, name: "Fixed Lot" },
  // { key: 8, name: "% of Equity" },
];

const NewMamProfile = ({ isOpen, toggle }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      getAccountTypesStart({
        forCp: true,
      })
    );
    return () => {
      dispatch(getAccountTypesStart());
    };
  }, []);

  return (
    <Modal centered isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle} tag="h4">
        {t("Create New Profile")}
      </ModalHeader>
      <ModalBody>
        <AvForm
          onValidSubmit={(e, v) => {
            const params = {
              feeSetting: {
                performanceFee: v.performanceFee,
                perLotFee: v.perLotFee,
                periodicManagementFee: v.periodicManagementFee,
                managementFee: v.managementFee,
              },
              profileType: v.allocationType,
              deductionSchedule: v.deductionTimeSchedule,
              username: v.username,
            };
            dispatch(requestMaster(params));
            toggle();
          }}
        >
          <AvField
            name="username"
            label={t("Username")}
            type="text"
            placeholder={t("Username")}
            validate={{
              required: {
                value: true,
                errorMessage: "Please enter a valid username",
              },
            }}
          />
          <AvFieldSelecvt
            type="select"
            name="allocationType"
            value={"100"}
            label={t("Allocation Type")}
            className="form-select mt-2"
            required
            options={allocationStrategies.map((item) => ({
              label: item.name,
              value: item.key,
            }))}
            validate={{
              required: {
                value: true,
                errorMessage: "Please select an allocation type",
              },
            }}
          />
          <AvFieldSelecvt
            type="select"
            name="deductionTimeSchedule"
            value={"100"}
            label={t("Deduction Time Schedule")}
            className="form-select mt-2"
            required
            options={deductionSchedule.map((item) => ({
              label: item.name,
              value: item.key,
            }))}
          />
          <Label for="performanceFee" className="my-1">
            {t("Performance Fee - (%)")}
          </Label>
          <AvField name="performanceFee" type="number" id="performanceFee"
            placeholder="Enter a Performance Fee"
            validate={{
              required: {
                value: true,
                errorMessage: "Please enter a performance fee",
              },
              min: {
                value: 0,
                errorMessage: "Please enter a valid performance fee",
              },
            }}
          />
          <Label for="performanceFee" className="my-1">
            {t("Management Fee - ($)")}
          </Label>
          <AvField name="managementFee" type="number" id="managementFee"
            placeholder="Enter a Management Fee"
            validate={{
              required: {
                value: true,
                errorMessage: "Please enter a management fee",
              },
              min: {
                value: 0,
                errorMessage: "Please enter a valid management fee",
              },
            }}
          />
          <Label for="periodicManagementFee" className="my-1">
            {t("Periodic Management Fee - ($)")}
          </Label>
          <AvField
            name="periodicManagementFee"
            type="number"
            id="periodicManagementFee"
            placeholder="Enter a Periodic Management Fee"
            validate={{
              required: {
                value: true,
                errorMessage: "Please enter a periodic management fee",
              },
              min: {
                value: 0,
                errorMessage: "Please enter a valid periodic management fee",
              },
            }}
          />
          <Label for="performanceFee" className="my-1">
            {t("Per Lot Fee - ($)")}
          </Label>
          <AvField name="perLotFee" type="number" id="perLotFee"
            placeholder="Enter a Per Lot Fee"
            validate={{
              required: {
                value: true,
                errorMessage: "Please enter a per lot fee",
              },
              min: {
                value: 0,
                errorMessage: "Please enter a valid per lot fee",
              },
            }}
          />
          <div className="text-center mt-3 mb-1">
            <Button className="btn-color-secondary w-25" onClick={toggle}>
              {t("Close")}
            </Button>
            <Button
              className="color-bg-btn border-0 text-white w-25 ms-3"
              type="submit"
            >
              {t("Submit")}
            </Button>
          </div>
        </AvForm>
      </ModalBody>
    </Modal>
  );
};

export default NewMamProfile;
