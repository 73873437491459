import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { withTranslation } from "react-i18next";
import s1 from "assets/slider/slider1.png";
import s2 from "assets/slider/slider2.png";

const Slider = () => {
  return (
    <div className='slider slider_home mb-3'>
      <div className='slider__container'>
        <OwlCarousel style={{ direction: "ltr" }} className="owl-theme" loop items={1}>
          <div className="justify-content-center align-items-center p-relative"
            style={{
              margin: 0,
              padding: 0,
              borderRadius: 25,
              overflow: "hidden",
            }}
          >
            <div className=""><img src={s1} alt="" /></div>
          </div> 
          <div className="justify-content-center align-items-center p-relative"
            style={{
              margin: 0,
              padding: 0,
              borderRadius: 25,
              overflow: "hidden",
            }}
          >
            <div className=""><img src={s2} alt="" /></div>
          </div> 
          <div className="justify-content-center align-items-center p-relative"
            style={{
              margin: 0,
              padding: 0,
              borderRadius: 25,
              overflow: "hidden",
            }}
          >
            <div className=""><img src={s1} alt="" /></div>
          </div> 
        </OwlCarousel>
      </div>
    </div>
  );
};
export default withTranslation()(Slider); 