
export const withdrawalConfig = [
  {
    gateway: "WIRE_TRANSFER",
    name: "Wire Transfer",
    image: "/img/payment-method/wire-transfer.png",
    allowed: {
      wallet: true,
      mt5: true,
    },
    config: {},
  },
  {
    gateway: "CRYPTO",
    name: "Crypto",
    image: "/img/payment-method/crypto.png",
    allowed: {
      wallet: true,
      mt5: true,
    },
    config: {
      methods: [
        {
          network: "TRC20",
          coins: ["USDT"]
        }
      ]
    },
  },
];