import React from "react";
import SearchableComponent from "./SearchableComponent";
import { getClients } from "apis/forex/requests";

export default function SearchableClient({
  customer, setCustomer, disallowed = null,
}) {
  return (
    <SearchableComponent
      placeholder={("Select Client")}
      label={"Client"}
      name={"client"}
      onChange={(e) => {
        e?.value ? setCustomer(e.value) : setCustomer(e);
      }}
      getData={
        async (payload) => getClients({ payload })
          .then((res) => (res?.result?.docs?.filter(
            (client) => client._id !== disallowed
          )))
      }
      value={customer}
      mapper={(client) => ({
        value: client,
        label: `${client?.fx?.isIb ? "IB" : "Client"} | ${client.firstName} ${client.lastName}`,
      })}
    />
  );
}
