import React, { useEffect, useState } from "react";
import { Input, InputGroup } from "reactstrap";
import { default as AvFieldSelect } from "components/Common/AvFieldSelect";
import { useDispatch, useSelector } from "react-redux";
import Loader from "components/Common/Loader";
import { getAccountsStart } from "store/actions";
export default function Mt5Details(props) {
  const {
    t,
    amount,
    setAmount,
    denyMinDeposit,
    amountError,
    conversionRate,
    baseCurrency,
    setSelectedAccount,
    selectedAccount,
    minDepositAmount,
  } = props;
  const dispatch = useDispatch();
  const { accounts, loading } = useSelector((state) => state.forex.accounts);
  const [minAmount, setMinAmount] = useState(minDepositAmount);

  useEffect(() => {
    setMinAmount(minDepositAmount);
  }, [minDepositAmount]);

  useEffect(() => {
    dispatch(
      getAccountsStart({
        limit: 100,
      })
    );
  }, []);

  return (
    <div className="w-100">
      {loading ? <Loader /> : accounts && (
        <>
          <InputGroup>
            <label className="w-100">{t("Amount in USD")}</label>
            <Input
              required
              onChange={(e) => {
                setAmount(e.target.value);
              }}
              className="form-control"
              type="number"
              min="0"
              value={amount}
              step="0.01"
              placeholder="Enter 15-128341 USD"
              onKeyPress={(e) => {
                if (!/[0-9.]|\./.test(e.key)) {
                  e.preventDefault();
                }
              }}
            
            />
          </InputGroup>
          {denyMinDeposit && (
            <p className="small text-danger "> {t(`The minimum deposit amount value for this account is ${minAmount} ${selectedAccount?.currency}`)}</p>
          )}
          <div className="mt-3">
            <AvFieldSelect
              disabled={false}
              options={
                accounts?.filter(a => !["DEMO", "MAM_FEE", "IB"].includes(a.type))?.filter(a => a.profile ? a.profile?.profileType === 9 : true).map(acc=>{
                  return {
                    label: `${acc.login} ${acc?.accountTypeId?.title || "-"} (${acc.Balance} ${acc.currency})`,
                    value: acc._id,
                    disabled: acc.depositDisabled ?? false,
                  };
                })
              }
              onChange={(e) => setSelectedAccount(accounts.find((acc) => acc._id === e))}
              name="tradingAccountId"
              type="text"
              errorMessage={t("MT5 account is required")}
              validate={{ required: { value: true } }}
              label={t("MT5 Account")}
            >
            </AvFieldSelect>
          </div>
          {amountError && (
            <p className="small text-danger "> {t("Please Select Account")}</p>
          )}
        </>
      )}
      
      {
        conversionRate !== 0 && amount !== 0 && amount && (
          <div className="text-center mt-4">
            <span className="fs-5">{t("Conversion Rate: " + ` ${amount} ` + " USD" + " = " + ` ${conversionRate?.toFixed(3) * amount} `) + baseCurrency}</span>
          </div>
        )
      }
    </div>
  );
}
