import { getConversionRate } from "apis/conversionRate";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Alert, Spinner } from "reactstrap";

export default function TransactionLimits({
  amt = 0,
  setIsValid,
  baseCurrency,
  isFx,
  isFromSelected,
  isInternalTransfer,
}) {
  const { t } = useTranslation();
  const { clientData } = useSelector((state) => state.Profile);
  const [amount, setAmount] = useState(amt);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (baseCurrency === "USD") {
      setAmount(amt);
    } else {
      if (baseCurrency && amt) {
        setLoading(true);
        getConversionRate({
          baseCurrency,
          targetCurrency: "USD",
        }).then((res) => {
          setAmount(amt * res);
        }).catch((err) => {
          console.log(err);
        }).finally(() => {
          setLoading(false);
        });
      }
    }
  }, [amt, baseCurrency]);

  const limitDaily = clientData?.settings?.transactionSettings?.[isInternalTransfer ? "transfer" : "withdrawal"]?.[isFx ? "fx" : "wallet"]?.limit?.[isFx ? "fx" : "wallet"]?.daily;
  const limitMonthly = clientData?.settings?.transactionSettings?.[isInternalTransfer ? "transfer" : "withdrawal"]?.[isFx ? "fx" : "wallet"]?.limit?.[isFx ? "fx" : "wallet"]?.monthly;
  const clientUsedDaily = clientData?.settings?.transactionSettings?.[isInternalTransfer ? "transfer" : "withdrawal"]?.[isFx ? "fx" : "wallet"]?.daily;
  const clientUsedMonthly = clientData?.settings?.transactionSettings?.[isInternalTransfer ? "transfer" : "withdrawal"]?.[isFx ? "fx" : "wallet"]?.monthly;
  const dailyBase = limitDaily - clientUsedDaily - amount;
  const monthlyBase = limitMonthly - clientUsedMonthly - amount;
  const daily = dailyBase  < 0 ? 0 : dailyBase;
  const monthly = monthlyBase < 0 ? 0 : monthlyBase;
  const dailyMessage = `${t("Daily available limit is")} ${daily?.toFixed(3)} ${clientData?.settings?.transactionSettings?.[isInternalTransfer ? "transfer" : "withdrawal"]?.[isFx ? "fx" : "wallet"]?.limit?.currency}`;
  const monthlyMessage = `${t("Monthly available limit is")} ${monthly?.toFixed(3)} ${clientData?.settings?.transactionSettings?.[isInternalTransfer ? "transfer" : "withdrawal"]?.[isFx ? "fx" : "wallet"]?.limit?.currency}`;

  useEffect(() => {
    if (dailyBase < 0 || monthlyBase < 0) {
      setIsValid(false);
    } else {
      setIsValid(true);
    }
  }, [dailyBase, monthlyBase]);
  
  if (!isFromSelected || !baseCurrency) return <></>;

  if (loading) return <>
    <Spinner color="primary" />
  </>;
  return (
    <div>
      <Alert color="info" className="mt-3">
        {dailyMessage}
      </Alert>
      <Alert color="info" className="mt-3">
        {monthlyMessage}
      </Alert>
      {(dailyBase < 0 || monthlyBase < 0) && <Alert color="danger" className="mt-3">
        {t("You have exceeded your daily or monthly limit")}
      </Alert>}
    </div>
  );
}
