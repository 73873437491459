import { allowedMethods } from "components/Deposit/Methods/allowedMethods";
import React from "react";
import {
  Col, Row, Button
} from "reactstrap";

export default function PaymentGateway(props) {
  const { type, setGateway, setType } = props;
  return (
    <>
      <Row className="text-center d-flex justify-content-center">
        {
          allowedMethods.filter((method) => method.allowed.includes(type)).map((method) => (  <>
            <Col xs={12} md={
              allowedMethods.filter((method) => method.allowed.includes(type)).length % 2 === 0 ? 3 : 2
            } key={method.gateway} className="p-4" >
              <button className="btn btn-soft-light waves-effect waves-light m-3 rounded bg-white shadow"
                onClick={()=>{
                  setGateway(method.gateway);
                }}
                style={{
                  height: "100%",
                  maxHeight: "120px",
                  width: "100%",
                  maxWidth: "120px",
                }}
              >
                <img src={method.image}
                  style={{
                    objectFit: "cover",
                    maxWidth: "100%",
                    maxHeight: "100%",
                  }} // Set width to 100% and height to auto to maintain aspect ratio
                  alt=""></img>
              </button>
            </Col>
          </>
          ))
        }
      </Row>
      <div className="ml-3">
        <Button
          className="mx-3 color-bg-btn border-0" 
          onClick={() => {
            setType("");
          }}
        >
          <i className="mdi mdi-arrow-left" />
          Back
        </Button>
      </div>
    </>
  );
}
