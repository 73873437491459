import CardWrapper from "components/Common/CardWrapper";
import React from "react";
import { useTranslation } from "react-i18next";
import { Thead } from "react-super-responsive-table";
// eslint-disable-next-line object-curly-newline
import { Row, Table } from "reactstrap";

const Investors = (props) => {
  const { t } = useTranslation();
  const { selectedTradingAccount } = props;
  const total = selectedTradingAccount?.slaves?.reduce((acc, cur) => acc + parseFloat(cur.Equity), 0);
  return (
    <>
      <h1 className="color-primary">{t("Investors")}</h1>
      <CardWrapper className="mt-4 px-5 py-4 glass-card">
        <Row className="border rounded-3">
          <Table
            borderless
            responsive
            hover
            className="cursor-pointer text-center mb-0"
          >
            <Thead className="table-light">
              <tr>
                <th>{t("Account")}</th>
                <th>{t("Currency")}</th>
                <th>{t("Balance")}</th>
                <th>{t("Share %")}</th>
                <th>{t("Credit")}</th>
                <th>{t("Equity")}</th>
                <th>{t("Margin")}</th>
                <th>{t("Margin Level")}</th>
                <th>{t("Free Margin")}</th>
              </tr>
            </Thead>
            <tbody className="border-top">
              {selectedTradingAccount && !selectedTradingAccount.slaves ? (
                <tr>
                  <td colSpan="100%" className="my-2">
                    {t("You currently don't have any accounts.")}
                  </td>
                </tr>
              ) : (
                selectedTradingAccount &&
                selectedTradingAccount.slaves?.sort((a, b) => parseFloat(b.Equity) - parseFloat(a.Equity)).map((slave) => (
                  <tr key={slave.login}>
                    <td className="centerV">{slave.login}</td>
                    <td className="centerV">{slave.currency}</td>
                    <td className="centerV">{slave.Balance}</td>
                    <td className="centerV">{parseFloat((parseFloat(slave.Equity)  * 100) / total)?.toFixed(2)}</td>
                    <td className="centerV">{slave.Credit}</td>
                    <td className="centerV">{slave.Equity}</td>
                    <td className="centerV">{slave.Margin}</td>
                    <td className="centerV">{slave.MarginLevel}</td>
                    <td className="centerV">{slave.MarginFree}</td>
                  </tr>
                ))
              )}
            </tbody>
          </Table>
        </Row>
      </CardWrapper>
    </>
  );
};

export default Investors;
