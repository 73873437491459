import logo from "assets/images/brands/brand-main-sm.png";
import footer from "assets/images/brands/logo.png";
import hLogo from "assets/images/brands/hlogo.png";

export const clientName = "Rox Capitals";
export const developedBy = "Rox Capitals";
export const imagesUrl = process.env.REACT_APP_API_IMAGES_URL;
export const cpUrl = process.env.REACT_APP_CP_URL;
export const apiUrl = process.env.REACT_APP_API_CP_DOMAIN;
export const companyName =  "Rox Capitals";
export const companyWebsite = "https://roxcapitals.com/";
export const privacyPolicyLink = "https://roxcapitals.com/privacy-policy-2/";
export const clientAgreementLink = "#";
export const sidebarLogo = hLogo;
export const smLogo = logo;
export const mainLogo = hLogo;
export const footerLogo = logo;
