import { enableFX } from "config";
import React from "react";
import { useTranslation } from "react-i18next";
import {
  Col, Row,
  Button,
} from "reactstrap";

export default function PaymentType(
  props
) {
  const { setType } = props;
  const { t } = useTranslation();
  return (
    <div className="mt-3 d-flex justify-content-around flex-column" >
      {
        <>
          <Row className="d-flex text-center mt-3" >
            <Col md={6}  >
              <Button
                outline 
                type="button"
                color="success"
                className="waves-effect waves-light w-lg m-2 btn-lg"
                onClick={() => setType("fiatDeposit")}
              >
                <Row>
                  <Col md="2" sm="2">
                    <i className="icofont-dollar icofont-4x" />
                  </Col>

                  <Col md="9" sm="9">
                    <div className="d-flex flex-column text-start">
                      {t("Deposit Wallet")} 
                      <small>{t("I want to deposit money to my wallet")}</small>
                    </div>
                  </Col>

                  <Col md="1" sm="1" className="align-self-center">
                    <i className="mdi mdi-arrow-right" />
                  </Col>
                </Row>
              </Button>
            </Col>
            {enableFX && <Col md={6} >
              <Button
                outline 
                type="button"
                color="success"
                className="waves-effect waves-light w-lg m-2 btn-lg"
                onClick={() => setType("mt5Deposit")}
              >
                <Row>
                  <Col md="2" sm="2">
                    <i className="icofont-dollar icofont-4x" />
                  </Col>

                  <Col md="9" sm="9">
                    <div className="d-flex flex-column text-start">
                      {t("Deposit MT5")} 
                      <small>{t("I want to deposit into MT5 Account")}</small>
                    </div>
                  </Col>

                  <Col md="1" sm="1" className="align-self-center">
                    <i className="mdi mdi-arrow-right" />
                  </Col>
                </Row>
              </Button>
            </Col>}
          </Row>
        </>
      }
    </div> 
  );
}
