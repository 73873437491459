import { generateTransactionOTPAPI, verifyOTPAPI } from "apis/auth";
import React from "react";
import { useTranslation } from "react-i18next";
import {
  Alert,
  Button, Input, InputGroup, Row
} from "reactstrap";

export default function VerifyOTP({
  isOTPVerified, setIsOTPVerified
}) {
  const [otp, setOtp] = React.useState("");
  const [otpError, setOtpError] = React.useState("Please verify OTP");
  const [otpSent, setOtpSent] = React.useState(false);
  const { t } = useTranslation();
  const handleSendOTP = () => {
    generateTransactionOTPAPI().then((res) => {
      setOtpSent(true);
      setOtpError("");
    }).catch((err) => {
      setOtpError(err.message);
    });
  };

  const handleVerifyOTP = () => {
    // eslint-disable-next-line no-debugger
    debugger;
    if (otp === "") {
      setOtpError("Please enter OTP");
      return;
    }
    if (otp.length < 4) {
      setOtpError("Please enter valid OTP");
      return;
    } else {
      verifyOTPAPI(otp).then((res) => {
        setIsOTPVerified(true);
      }).catch((err) => {
        setOtpError(err.message);
      });
    }
  };
  return (
    <div className="ms-3">
      <Row className="w-100">
        <InputGroup
          className="input-group-alternative mb-3"
          style={{ width: "100%" }}
          aria-label="OTP"
        >
          <Button color="primary" type="button"
            disabled={otpSent}
            onClick={handleSendOTP}
            className="">Send OTP</Button>
          <Input
            type="text"
            placeholder="Enter OTP"
            aria-label="Enter OTP"
            aria-describedby="otp"
            className="form-control"
            value={otp}
            onChange={(e) => {
              setOtp(e.target.value);
              setOtpError("");
            }}
          />
          <Button color="primary" type="button"
            disabled={otp === "" || !otpSent}
            onClick={handleVerifyOTP}
            className="">Verify</Button>
        </InputGroup>
        
        {
          (!isOTPVerified && otpError !== "") && (
            <Row>
              <Alert className="text-center my-2" color="danger">
                {t(otpError)}
              </Alert>
            </Row>
          )
        }
      </Row>
    </div>
  );
}
