import CardWrapper from "components/Common/CardWrapper";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { MetaTags } from "react-meta-tags";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
// eslint-disable-next-line object-curly-newline
import { Button, Container, Spinner } from "reactstrap";
import {
  fetchProfile, getInvestorRequestStatus, requestInvestor 
} from "store/actions";

function RequestInvestor() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { loading, result, status, getStatusLoader } = useSelector(
    (state) => state.forex.requests.investor
  );

  useEffect(() => {
    dispatch(getInvestorRequestStatus());
  }, []);

  return (
    <div className="page-content mt-5">
      <MetaTags>
        <title>Investor</title>
      </MetaTags>
      <Container>
        <CardWrapper className="glass-card shadow-lg">
          <h4 className="border-bottom pb-2 color-primary">
            {t("Copy Trading")}
          </h4>
          <div className="text-center">
            {getStatusLoader ? (
              <Spinner></Spinner>
            ) : status && status.length > 0 ? (
              <>
                {status === "PENDING" && (
                  <h5 className="my-5">{t("Your Request under processing")}</h5>
                )}
                {status === "APPROVED" && (
                  <h5 className="my-5">
                    {t("Your Request has been approved")}
                  </h5>
                )}
                {status === "REJECTED" && (
                  <h5 className="my-5">
                    {t("Your Request has been Rejected")}
                  </h5>
                )}
              </>
            ) : result ? (
              <>
                <div>
                  <i className="bx bx-check" style={{ fontSize: "80px" }}></i>
                </div>
                <h5 className="mb-3">
                  {t(
                    "You can now access Copy Trading Portal from the Dashboard."
                  )}
                </h5>
              </>
            ) : (
              <>
                <h5 className="my-5">
                  {t(
                    "Please click the button below to access the Copy Trading Portal."
                  )}
                </h5>
                <Button
                  className="color-bg-btn border-0"
                  onClick={() => {
                    dispatch(requestInvestor());
                    dispatch(fetchProfile());
                  }}
                  disabled={loading}
                >
                  {loading ? <Spinner></Spinner> : t("Access  Copy Trading")}
                </Button>
              </>
            )}
          </div>
        </CardWrapper>
      </Container>
    </div>
  );
}

export default RequestInvestor;
