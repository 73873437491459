import React from "react";
import {
  Container,
  Col, Row,
  Button,
} from "reactstrap";

export default function PaymentDetails(props) {
  const { gateway, type, setGateway } = props;
  return (
    <>
      <Row></Row>
      
      <div className="ml-3">
        <Button
          className="mx-3 color-bg-btn border-0" 
          onClick={() => {
            setGateway("");
          }}
        >
          <i className="mdi mdi-arrow-left" />
          Back
        </Button>
      </div>
    </>
  );
}
